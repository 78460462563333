export const MainNav = [
    {
        icon: 'lnr-chart-bars',
        label: 'Dashboard',
        to: '#/dashboard',
    },

    {
        icon: 'lnr-graduation-hat',
        label: 'Students',
        to: '#/students',
    },
    {
        icon: 'pe-7s-user-female',
        label: 'Teachers',
        to: '#/teachers',
    },
    {
        icon: 'pe-7s-users',
        label: 'Groups',
        to: '#/groups',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Assignments',
        to: '#/assignments',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Study Material',
        to: '#/study-material',
    },
    {
        icon: 'lnr-film-play',
        label: 'Lecture Streams',
        to: '#/streams',
    },
    {
        icon: 'lnr-laptop-phone',
        label: 'Online Classes',
        to: '#/conferences',
    },

    {
        icon: 'pe-7s-notebook',
        label: 'Quizzes',
        to: '#/quiz',
    },
    {
        icon: 'lnr-envelope',
        label: 'Send Credentials',
        content: [
            {
                label: 'Students',
                to: '#/messages/students',
            },
            {
                label: 'Teachers',
                to: '#/messages/teachers',
            }
        ]
    },
    {
        icon: 'lnr-envelope',
        label: 'Reports',
        to: '#/reports',
    },
    {
        icon: 'lnr-cog',
        label: 'View Profile',
        to: '#/settings',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Feedback',
        to: '#/feedback',
    },
    {
        icon: 'pe-7s-compass',
        label: 'Take A Tour',
        to: '#/tour',
    },
];
