import { POST } from '../utilities/AxiosHelpers';
import axios from 'axios';
import { DOMAIN_URL } from '../config/constants';

export function loginApi(username, password) {
  const formData = new FormData();
  formData.append('email', username);
  formData.append('password', password);
  return POST('admin/login', formData);
}

export function refreshTokenApi() {
  return POST('admin/refresh');
}

export function logoutApi() {
  return POST('admin/logout');
}

export const requestOtpApi = (data) => {
  return POST('admin/otp/send', data);
};

export const verifyOtpApi = (data) => {
  return POST('admin/otp/verify', data);
};

export const resetPasswordApi = (data) => {
  return POST('admin/forgot/change_password', data);
};

export const updatePasswordApi = (data) => {
  return POST('admin/update_password', data);
};

export function getPackages() {
  const url = `${DOMAIN_URL}/api/v1/system_admin/packages`;
  return axios.get(url);
}