import React, { Component, Fragment } from 'react';
import { Button, Card, CardTitle, CardBody, Form, FormGroup, FormFeedback, Input, Label, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { loginUser } from '../../reducers/AdminAuth';
import { SUBDOMAIN_KEY } from '../../config/constants';
import { requestOtpApi, verifyOtpApi, resetPasswordApi } from '../../api/auth_api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/utils/images/Horizontal_Logo.png';
import Register from './register';

const LOGIN = 'LOGIN';
const REGISTER = 'REGISTER';
const REQUEST_OTP = 'REQUEST_OTP';
const VERIFY_OTP = 'VERIFY_OTP';
const RESET_PASSWORD = 'RESET_PASSWORD';

class Login extends Component {
  state = {
    username: '',
    password: '',
    password_confirmation: '',
    mobile: '',
    subdomain: '',
    otp: '',
    token: '',
    screen: LOGIN,
  }

  componentDidMount() {
    const subdomain = localStorage.getItem(SUBDOMAIN_KEY);
    this.setState({ subdomain });
  }

  handleEnterPress(e) {
    if(e.charCode === 13) {
      switch (this.state.screen) {
        case LOGIN:
          this.props.loginUser({ username: this.state.username, password: this.state.password });
          break;
        case REQUEST_OTP:
          this.requestOtp();
          break;
        case VERIFY_OTP:
          this.verifyOtp();
          break;
        case RESET_PASSWORD:
          this.resetPassword();
          break;
        default:
        break;
      }
    }
  }

  setSubdomain(subdomain) {
    localStorage.setItem(SUBDOMAIN_KEY, subdomain);
    this.setState({ subdomain });
  }

  requestOtp() {
    this.setState({ loading: true });
    requestOtpApi({
      email: this.state.username,
    })
    .then((data) => {
      toast.success(data.message);
      this.setState({ loading: false, screen: VERIFY_OTP, mobile_number: data.mobile_number, password: '' });
    })
    .catch((e) => {
      this.setState({ loading: false });
      if (e.data) {
        if (e.data.insufficient_sms_credits || !e.data.otp_reset_enable) {
          toast.error('OTP password reset disabled. Please contact our support team for assistance');
          return;
        }
      }
      toast.error('Unable to send OTP, check username and try again');
    });
  }

  verifyOtp() {
    this.setState({ loading: true });
    verifyOtpApi({
      mobile_number: this.state.mobile_number,
      otp: this.state.otp,
      call_func: 'generate_forgot_token',
      func_data: {
        email: this.state.username
      },
    })
    .then((res) => {
      toast.success(res.message);
      this.setState({ loading: false, token: res.token, screen: RESET_PASSWORD, otp: '' });
    })
    .catch(() => {
      toast.error('Unable to verify OTP');
      this.setState({ loading: false });
    });
  }

  resetPassword() {
    if (this.state.password === this.state.password_confirmation && this.state.password.length >= 8) {
      this.setState({ loading: true });
      resetPasswordApi({
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        email: this.state.username,
        token: this.state.token,
      })
      .then((res) => {
        toast.success('Password reset successfully');
        this.setState({ loading: false, screen: LOGIN });
      })
      .catch((e) => {
        toast.error('Unable to Reset Password');
        this.setState({ loading: false });
      });
    } else {
      toast.error('Password and Confirm Password must be same and at least 8 characters long.');
    }
  }

  renderLogin() {
    return(
      <CardBody>
        <CardTitle>Login</CardTitle>
        <Form className="mt-4">
          <FormGroup row>
            <Label className="text-muted">Organization Code</Label>
            <Input value={this.state.subdomain} onChange={(e) => this.setSubdomain(e.target.value)} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Organization Code" />
          </FormGroup>
          <FormGroup row>
            <Label className="text-muted">Username</Label>
            <Input invalid={this.props.AdminAuth.loginError} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Username" />
          </FormGroup>
          <FormGroup row>
            <Label className="text-muted">Password</Label>
            <Input invalid={this.props.AdminAuth.loginError} type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Password"/>
            <FormFeedback>Unable to login</FormFeedback>
          </FormGroup>
        </Form>
        {
          (this.props.AdminAuth.loggingIn) ?
          <Spinner size="lg" color="primary" /> :
          <div className="mt-4 row justify-content-end">
            <p className="mr-3" style={{ color: '#2FA4E7', cursor: 'pointer', marginTop: 2 }} onClick={() => this.setState({ screen: REQUEST_OTP })}>Unable to Login?</p>
            <div>
              <Button onClick={() => this.props.loginUser({ username: this.state.username, password: this.state.password })} color="primary">Login</Button>
            </div>
          </div>
        }

<p className="mr-3" style={{ color: '#2FA4E7', cursor: 'pointer', marginTop: 2 }} onClick={() => this.setState({ screen: REGISTER })}>Register Now</p>
      </CardBody>
    );
  }

  renderRequestOtp() {
    return(
      <CardBody>
        <CardTitle className="d-flex flex-row justify-content-center">
          <i
            style={{ fontSize: 20, color: '#343a40', position: 'absolute', left: 6, marginTop: 2, cursor: 'pointer' }}
            className="lnr-arrow-left ml-3"
            onClick={() => this.setState({ screen: LOGIN })}
          />
          Forgot Password
        </CardTitle>
        <Form className="mt-4">
          <FormGroup row>
            <Label className="text-muted">Organization Code</Label>
            <Input value={this.state.subdomain} onChange={(e) => this.setSubdomain(e.target.value)} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Organization Code" />
          </FormGroup>
          <FormGroup row>
            <Label className="text-muted">Username</Label>
            <Input value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Username" />
          </FormGroup>
        </Form>
        {
          (this.state.loading) ?
          <Spinner size="lg" color="primary" /> :
          <div className="mt-4 row justify-content-end">
            <div>
              <Button onClick={() => this.requestOtp()} color="primary">Request OTP</Button>
            </div>
          </div>
        }
      </CardBody>
    );
  }

  renderVerifyOtp() {
    return(
      <CardBody>
        <CardTitle className="d-flex flex-row justify-content-center">
          <i
            style={{ fontSize: 20, color: '#343a40', position: 'absolute', left: 6, marginTop: 2, cursor: 'pointer' }}
            className="lnr-arrow-left ml-3"
            onClick={() => this.setState({ screen: REQUEST_OTP })}
          />
          Verify OTP
        </CardTitle>
        <div className="mt-4">
          <FormGroup row>
            <Label className="text-muted">OTP</Label>
            <Input value={this.state.otp} onChange={(e) => this.setState({ otp: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter OTP" />
          </FormGroup>
        </div>
        {
          (this.state.loading) ?
          <Spinner size="lg" color="primary" /> :
          <div className="mt-4 row justify-content-end">
            <div>
              <Button onClick={() => this.verifyOtp()} color="primary">Verify OTP</Button>
            </div>
          </div>
        }
      </CardBody>
    );
  }

  renderResetPassword() {
    return(
      <CardBody>
        <CardTitle className="d-flex flex-row justify-content-center">
          <i
            style={{ fontSize: 20, color: '#343a40', position: 'absolute', left: 6, marginTop: 2, cursor: 'pointer' }}
            className="lnr-arrow-left ml-3"
            onClick={() => this.setState({ screen: REQUEST_OTP })}
          />
          Change Password
        </CardTitle>
        <Form className="mt-4">
          <FormGroup row>
            <Label className="text-muted">Password</Label>
            <Input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Password" />
          </FormGroup>
          <FormGroup row>
            <Label className="text-muted">Confirm Password</Label>
            <Input type="password" value={this.state.password_confirmation} onChange={(e) => this.setState({ password_confirmation: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Re-Enter Password" />
          </FormGroup>
        </Form>
        {
          (this.state.loading) ?
          <Spinner size="lg" color="primary" /> :
          <div className="mt-4 row justify-content-end">
            <div>
              <Button onClick={() => this.resetPassword()} color="primary">Reset Password</Button>
            </div>
          </div>
        }
      </CardBody>
    );
  }

  render() {
      if(this.state.screen != REGISTER) {
    return (
      <Fragment>
          <div className="loader-container">
            {
              (this.props.AdminAuth.refreshingToken) ?
              <Spinner size="lg" color="primary" /> :
              <div className="col-12 row">
                <div className="d-none d-sm-block col-sm-5 col-xl-4 h-100 login-sidebar">
                  <div className="d-flex flex-column justify-content-end h-75">
                    <span style={styles.imageOverlaySmallText}>Welcome to</span>
                    <span style={styles.imageOverlayText}><span style={styles.orangeText}>M</span>ind<span style={styles.orangeText}>h</span>osts</span>
                  </div>
                  <div className="d-flex flex-column justify-content-end h-25">
                    <a href="https://indgiants.com" target="_blank"><p style={styles.bottomTextStyle}>Powered by Indgiants ©️2021</p></a>
                  </div>
                </div>
                <div className="col-sm-7 col-xl-8 loader-container">
                  <img src={Logo} alt="logo" style={{ position: 'absolute', top: 16, width: 240 }} />
                  <Card className="col-sm-12 col-lg-10 col-xl-8" style={{ marginTop: 100 }}>
                    {this.state.screen === LOGIN && this.renderLogin()}
                    {this.state.screen === REQUEST_OTP && this.renderRequestOtp()}
                    {this.state.screen === VERIFY_OTP && this.renderVerifyOtp()}
                    {this.state.screen === RESET_PASSWORD && this.renderResetPassword()}
                  </Card>
                </div>
              </div>
            }
          </div>
      </Fragment>
    );
  }else{
    return <Register />
  }
  }
}

const mapStateToProp = state => ({
    AdminAuth: state.AdminAuth,
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (payload) => loginUser(dispatch, payload),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(Login);

const styles = {
  imageOverlaySmallText: {
    color: '#fff',
    textAlign: 'left',
    fontFamily: 'Arima Madurai, cursive',
    fontSize: 28,
  },
  imageOverlayText: {
    color: '#fff',
    textAlign: 'left',
    fontFamily: 'Arima Madurai, cursive',
    fontSize: 46,
    marginTop: 25,
  },
  orangeText: {
    color: '#f56f1a'
  },
  bottomTextStyle: {
    color: '#fff',
    textAlign: 'right',
    fontSize: 12,
    // fontFamily: 'Helvetica',
  }
}
